import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Box, Tabs, Tab } from "@mui/material";
import ProfileStats from "./ProfileStats";
import ProfileBikes from "./ProfileBikes";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BikeIcon from "@mui/icons-material/DirectionsBike";
import ProfileRides from "./ProfileRides";
import RiderStats from "./RiderStats";

const Profile = () => {
  const { uid } = useParams(); // Obtiene el UID del usuario desde la URL.
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Container>
      <ProfileStats uid={uid} />
      <Box sx={{ mt: 2, borderRadius: "2% 2% 0 0", overflow: "hidden" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          centered
          sx={{
            backgroundColor: "#f0f0f0",
            "& .MuiTabs-indicator": {
              backgroundColor: "#000",
            },
            "& .MuiTab-root": {
              minHeight: "48px",
              fontWeight: "bold",
              color: "#000",
              "&.Mui-selected": {
                color: "#000",
                backgroundColor: "#e0e0e0",
              },
            },
          }}
        >
          <Tab
            icon={<BikeIcon />}
            label="Bikes"
            sx={{
              "&.Mui-selected .MuiSvgIcon-root": {
                color: "#000",
              },
            }}
          />
          <Tab
            icon={<DirectionsBikeIcon />}
            label="Rides"
            sx={{
              "&.Mui-selected .MuiSvgIcon-root": {
                color: "#000",
              },
            }}
          />
          <Tab
            icon={<AssessmentIcon />}
            label="Rider Stats"
            sx={{
              "&.Mui-selected .MuiSvgIcon-root": {
                color: "#000",
              },
            }}
          />
        </Tabs>

        {/* Renderizado condicional de los componentes de cada pestaña */}
        <Box p={3}>
          {tabIndex === 0 && <ProfileBikes uid={uid} />}
          {tabIndex === 1 && <ProfileRides uid={uid} />}
          {tabIndex === 2 && <RiderStats uid={uid} />}
        </Box>
      </Box>
    </Container>
  );
};

export default Profile;
