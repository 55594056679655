import React from "react";

import Header from "../components/Home/Header";
import Footer from "../components/Home/Footer";
import ProductSlider from "./Front/ProductSlider";
import CategorySlider from "./store/CategorySlider";

const Welcome = () => {
  return (
    <>
      <Header />
      <CategorySlider />
      <ProductSlider />
      <Footer />
    </>
  );
};

export default Welcome;
