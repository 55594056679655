// App.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { isMobile } from "react-device-detect";
import Welcome from "./components/Welcome";
import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import PhoneSignUp from "./components/PhoneSignUp";
import ProtectedRoute from "./components/ProtectedRoute";
import NotAuthorizedScreen from "./screens/NotAuthorizedScreen.jsx";
import UserMenu from "./components/Users/UserMenu";
import NavigationBar from "./Navbar";
import UserForm from "./components/Users/UserForm.js";
import ControlPanel from "./components/AdminPanel/ControlPanel";
import { UserAuthContextProvider } from "./context/userAuthContext";
import { CartProvider } from "./context/CartContext";
import Profile from "./components/Profile/Profile";
import SearchFriends from "./components/Friends/SearchFriends";
import FriendRequests from "./components/Users/FriendRequests";
import SearchPanel from "./components/Search/SearchPanel";
import CheckOut from "./components/CheckOut/CheckOut.js";
import Success from "./components/CheckOut/Success";
import Failure from "./components/CheckOut/Failure";
import Pending from "./components/CheckOut/Pending";
import CreateStore from "./components/Stores/CreateStore.js";
import AllStores from "./components/Stores/AllStores.js";
import MobileNavbar from "./MobileNavbar.js";
import NewUser from "./components/Users/NewUser.js";
import Wallet from "./components/SpeedCoins/Wallet.js";
import TransactionDetails from "./components/SpeedCoins/TransactionDetails.js";
import MyFriends from "./components/Users/MyFriends.js";
import Notifications from "./Notifications.js";
import SendNotification from "./components/SendNotification.js";
import AnalyticsTracker from "./AnalyticsTracker.js";
import FloatingSupportButton from "./components/Support/FloatingSupportButton.js";
import { Box } from "@mui/material";

import ProductDataFetcher from "./components/store/ProductDataFetcher.js";
import Categorias from "./components/store/categorias/Categorias.js";
import ProductosCategoria from "./components/store/categorias/ProductosCategoria.js";
import { CurrencyProvider } from "./context/CurrencyContext.js";
import Search from "./components/store/productos/Search.js";
import Instaladores from "./components/MicroServicios/Instaladores.jsx";
import Servicios from "./components/MicroServicios/Servicios.jsx";
import Cart from "./components/store/carrito/Cart.jsx";

function App() {
  return (
    <UserAuthContextProvider>
      <CurrencyProvider>
        <CartProvider>
          <NavigationBar />
          <Box
            sx={{
              paddingTop: isMobile ? "10px" : "60px",
              paddingBottom: "20px",
            }}
          ></Box>
          <AnalyticsTracker />
          <Notifications />
          <Routes>
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/producto/:documentName"
              element={<ProductDataFetcher />}
            />
            <Route
              path="/productos/categoria/:id"
              element={<ProductosCategoria />}
            />
            <Route path="/search/:input" element={<Search />} />
            <Route path="/categorias" element={<Categorias />} />
            <Route path="/sendNotification" element={<SendNotification />} />
            <Route path="/MyFriends/:uid" element={<MyFriends />} />
            <Route path="/transaction/:uid" element={<TransactionDetails />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/notauthorized" element={<NotAuthorizedScreen />} />
            <Route path="/search" element={<SearchPanel />} />
            <Route path="/friends" element={<SearchFriends />} />
            <Route path="/checkout/:uid" element={<CheckOut />} />
            <Route
              path="/geekdashboard"
              element={
                <ProtectedRoute role="admin">
                  <ControlPanel />
                </ProtectedRoute>
              }
            />
            <Route path="/stores" element={<AllStores />} />
            <Route path="/createstore" element={<CreateStore />} />
            <Route path="/success/:orderId" element={<Success />} />
            <Route path="/failure" element={<Failure />} />
            <Route path="/pending" element={<Pending />} />
            <Route path="/friendsrequests" element={<FriendRequests />} />
            <Route path="/" element={<Welcome />} />
            <Route path="/user/:uid" element={<Profile />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/phonesignup" element={<PhoneSignUp />} />
            <Route path="/addProfile" element={<UserForm />} />
            <Route path="/profile" element={<UserMenu />} />
            <Route path="/welcomeaboard" element={<NewUser />} />
            <Route path="/instaladores" element={<Servicios />} />
            <Route path="/carrito" element={<Cart />} />
          </Routes>
          <MobileNavbar />
          <FloatingSupportButton />
        </CartProvider>
      </CurrencyProvider>
    </UserAuthContextProvider>
  );
}

export default App;
