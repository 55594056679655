import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductCarousel = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://radiosistemas-a46cd.uc.r.appspot.com/productos/search?query=Ferreteria"
        );
        setProducts(response.data.productos);
      } catch (err) {
        setError("Error al cargar los productos.");
        console.error("Error fetching products:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <div className="w-10 h-10 border-4 border-t-green-500 border-gray-200 rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return <p className="text-red-500 text-center py-4">{error}</p>;
  }

  return (
    <div className="max-w-screen-lg mx-auto py-8 px-4">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">
        Productos de Ferretería
      </h2>
      <Slider {...sliderSettings} className="overflow-hidden">
        {products.map((product) => (
          <div key={product.producto_id} className="flex justify-center px-2">
            <div className="bg-white rounded-lg shadow-md overflow-hidden w-full max-w-xs hover:shadow-lg transition-transform transform hover:-translate-y-1">
              {product.descuento && (
                <span className="absolute top-2 left-2 bg-red-500 text-white text-xs font-bold py-1 px-2 rounded">
                  -{product.descuento}%
                </span>
              )}
              <Link
                to={`/producto/${product.modelo.replace(/\//g, "-")}`}
                className="block"
              >
                <img
                  src={product.img_portada || "https://via.placeholder.com/300"}
                  alt={product.titulo}
                  className="w-full h-44 object-contain p-2"
                />
                <div className="p-4">
                  <h3 className="text-lg font-medium text-gray-700 truncate mb-2">
                    {product.titulo}
                  </h3>
                  <div className="text-green-600 font-bold text-xl mb-1 flex items-center space-x-2">
                    <span>${product.precios?.precio_lista}</span>
                    {product.precios?.precio_anterior && (
                      <span className="text-gray-500 text-sm line-through">
                        ${product.precios.precio_anterior}
                      </span>
                    )}
                  </div>
                  <p className="text-sm text-gray-500">
                    Existencias:{" "}
                    {product.total_existencia > 0
                      ? product.total_existencia
                      : "Agotado"}
                  </p>
                </div>
              </Link>
              <div className="flex justify-between items-center px-4 py-2 border-t">
                <button className="bg-green-500 text-white text-sm font-medium py-2 px-4 rounded hover:bg-green-600 transition">
                  Agregar
                </button>
                <button
                  aria-label="Agregar a favoritos"
                  className="text-gray-500 hover:text-red-500 transition"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5.121 19.071A10.013 10.013 0 0112 21c2.761 0 5.263-1.111 7.121-2.929a9.965 9.965 0 002.829-7.071c0-2.761-1.111-5.263-2.929-7.121A10.013 10.013 0 0012 3c-2.761 0-5.263 1.111-7.121 2.929A10.013 10.013 0 002 12c0 2.761 1.111 5.263 2.929 7.121z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductCarousel;
