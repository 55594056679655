import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Modal } from "@mui/material";
import {
  Truck,
  Package,
  Download,
  Star,
  ShoppingCart,
  Zap,
} from "lucide-react";
import ImageSlider from "./ImageSlider";
import { CurrencyContext } from "../../context/CurrencyContext";
import { useCart } from "../../context/CartContext";

const ProductDetail = ({ product, syscomData }) => {
  const [openStockModal, setOpenStockModal] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const { currency, tipoCambio, incrementoPrecio } =
    useContext(CurrencyContext);
  const { addToCart } = useCart();

  const totalStock = syscomData?.total_existencia || 0;

  const getPrice = () => {
    const precioLista = syscomData.precios?.precio_descuento || 0;
    return currency === "MXN"
      ? (precioLista * tipoCambio * incrementoPrecio).toFixed(2)
      : (precioLista * incrementoPrecio).toFixed(2);
  };

  const handleAddToCart = () => {
    if (totalStock < 1) {
      alert("Lo sentimos, no hay stock disponible.");
      return;
    }
    addToCart({
      id: product.Modelo,
      name: product.Título,
      price: parseFloat(getPrice()),
      quantity,
      image: product["Imagen Principal"] || syscomData?.imagenes?.[0] || "",
    });
    alert("Producto agregado al carrito.");
  };

  const renderStockDetail = () => {
    const stockDetail = syscomData?.existencia?.detalle || [];
    return stockDetail.length > 0
      ? stockDetail.map((location, index) => (
          <li key={index} className="text-sm flex justify-between">
            <span className="font-medium">{location.almacen}</span>
            <span className="text-green-600">{location.cantidad} unidades</span>
          </li>
        ))
      : "No hay detalles de almacén disponibles.";
  };

  const renderRatingStars = (rating = 4) => {
    return [...Array(5)].map((_, index) => (
      <Star
        key={index}
        className={`w-5 h-5 ${
          index < rating ? "text-yellow-400" : "text-gray-300"
        }`}
        fill={index < rating ? "#FFC107" : "currentColor"}
      />
    ));
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="container mx-auto px-4 py-8 max-w-7xl">
        {/* Breadcrumb */}
        <nav className="mb-4 text-sm text-gray-600">
          <Link to="/" className="hover:text-green-600 transition">
            Inicio
          </Link>
          {" / "}
          <span className="text-gray-900">{product.Título}</span>
        </nav>

        {product ? (
          <div className="grid md:grid-cols-2 gap-8 bg-white rounded-xl shadow-lg p-6">
            {/* Image Gallery */}
            <div className="relative">
              {syscomData.marca_logo && (
                <img
                  src={syscomData.marca_logo}
                  alt="Logo de la marca"
                  className="absolute top-4 right-4 max-w-[100px] h-auto z-10"
                />
              )}
              {syscomData?.imagenes?.length > 0 ? (
                <ImageSlider images={syscomData.imagenes} />
              ) : (
                <img
                  src={product["Imagen Principal"]}
                  alt={product.Título}
                  className="w-full h-full object-contain"
                />
              )}
            </div>

            {/* Product Information */}
            <div className="space-y-4">
              <h1 className="text-3xl font-bold text-gray-900">
                {product.Título}
              </h1>

              {/* Rating */}
              <div className="flex items-center space-x-2">
                <div className="flex">{renderRatingStars()}</div>
                <span className="text-sm text-gray-600">
                  (234 calificaciones)
                </span>
              </div>

              {/* Price */}
              <div className="space-y-1">
                <p className="text-4xl font-bold text-green-600">
                  ${getPrice()} {currency}
                </p>
                <p className="text-sm text-gray-500">IVA incluido</p>
              </div>

              {/* Product Details */}
              <div className="grid grid-cols-2 gap-2 text-sm">
                <div>
                  <strong>Modelo:</strong> {product.Modelo}
                </div>
                <div>
                  <strong>Marca:</strong> {syscomData.marca || product.Marca}
                </div>
              </div>

              {/* Badges */}
              <div className="grid grid-cols-2 gap-2">
                <div className="flex items-center space-x-2 bg-green-50 p-2 rounded">
                  <Truck className="text-green-600" />
                  <span className="text-sm text-green-800">Envío GRATIS</span>
                </div>
                <div
                  className="flex items-center space-x-2 bg-blue-50 p-2 rounded cursor-pointer"
                  onClick={() => setOpenStockModal(true)}
                >
                  <Package className="text-blue-600" />
                  <span className="text-sm text-blue-800">
                    Stock: {totalStock} unidades
                  </span>
                </div>
              </div>

              {/* Quantity Selector */}
              <div className="flex items-center space-x-4">
                <label className="text-sm font-medium">Cantidad:</label>
                <div className="flex items-center border rounded">
                  <button
                    onClick={() => setQuantity(Math.max(1, quantity - 1))}
                    className="px-3 py-1 bg-gray-100 hover:bg-gray-200"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    min="1"
                    max={totalStock}
                    value={quantity}
                    onChange={(e) =>
                      setQuantity(
                        Math.min(Math.max(1, e.target.value), totalStock)
                      )
                    }
                    className="w-16 text-center"
                  />
                  <button
                    onClick={() =>
                      setQuantity(Math.min(totalStock, quantity + 1))
                    }
                    className="px-3 py-1 bg-gray-100 hover:bg-gray-200"
                  >
                    +
                  </button>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="grid grid-cols-2 gap-4">
                <button
                  onClick={handleAddToCart}
                  className="flex items-center justify-center space-x-2 bg-green-600 hover:bg-green-700 text-white font-bold py-3 rounded transition"
                >
                  <ShoppingCart />
                  Agregar al carrito
                </button>
                <button className="flex items-center justify-center space-x-2 bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-3 rounded transition">
                  <Zap />
                  Comprar ahora
                </button>
              </div>

              {/* Product Features */}
              <div className="bg-gray-50 p-4 rounded">
                <h2 className="text-lg font-semibold mb-2">
                  Acerca de este artículo
                </h2>
                <ul className="list-disc pl-5 text-sm text-gray-700 space-y-1">
                  {syscomData.caracteristicas?.map((caracteristica, index) => (
                    <li key={index}>{caracteristica}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center text-gray-600 mt-10">
            No se encontró el producto.
          </div>
        )}

        {/* Stock Modal */}
        <Modal open={openStockModal} onClose={() => setOpenStockModal(false)}>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-md">
            <h2 className="text-xl font-bold mb-4 text-green-600">
              Detalles de Stock
            </h2>
            <ul className="space-y-2">{renderStockDetail()}</ul>
          </div>
        </Modal>

        {/* Description Section */}
        {syscomData.descripcion && (
          <div className="mt-8 bg-white rounded-xl shadow-lg p-6">
            <h2 className="text-2xl font-bold mb-4 text-gray-900">
              Descripción del producto
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: syscomData.descripcion }}
              className="text-sm text-gray-700 leading-relaxed"
            ></div>
          </div>
        )}

        {/* Additional Resources */}
        {syscomData.recursos?.length > 0 && (
          <div className="mt-8 bg-white rounded-xl shadow-lg p-6">
            <h2 className="text-2xl font-bold mb-4 text-gray-900">
              Recursos adicionales
            </h2>
            <div className="grid md:grid-cols-2 gap-4">
              {syscomData.recursos.map((recurso, index) => (
                <a
                  key={index}
                  href={recurso.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-between bg-green-50 hover:bg-green-100 p-3 rounded-lg transition"
                >
                  <div className="flex items-center space-x-3">
                    <Download className="text-green-600" />
                    <span className="font-medium text-green-800">
                      {recurso.recurso}
                    </span>
                  </div>
                  <span className="text-sm text-gray-500">Descargar</span>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetail;
