import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Typed from "typed.js";
import algoliasearch from "algoliasearch/lite";

const AlgoliaSearch = () => {
  const [query, setQuery] = useState("");
  const [isTypedActive, setIsTypedActive] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const typedElement = useRef(null);
  const navigate = useNavigate();

  // Configuración de Algolia
  const client = algoliasearch(
    "CE2DHOJRGG",
    "b8c31052f535789944e9689e8f549bb4"
  );
  const index = client.initIndex("radeo");

  useEffect(() => {
    let typedInstance;
    if (typedElement.current && isTypedActive) {
      typedInstance = new Typed(typedElement.current, {
        strings: [
          "Cámara inteligente",
          "Router WIFI",
          "Taladro",
          "Paneles solares",
        ],
        typeSpeed: 100,
        backSpeed: 50,
        loop: true,
        showCursor: false,
      });

      return () => {
        typedInstance.destroy();
      };
    }
  }, [isTypedActive]);

  const handleSearch = () => {
    if (query.trim()) {
      index
        .search(query, {
          attributesToRetrieve: ["Título", "Marca", "Modelo"],
        })
        .then(({ hits }) => {
          setSearchResults(hits);
        })
        .catch((err) => {
          console.error("Error buscando en Algolia:", err);
        });
    } else {
      setSearchResults([]);
    }
  };

  const handleInputFocus = () => {
    setIsTypedActive(false);
  };

  const handleResultClick = (id) => {
    navigate(`/producto/${id}`);
    setSearchResults([]);
  };

  const handleClearSearch = () => {
    setQuery("");
    setSearchResults([]);
    setIsTypedActive(true);
  };

  const handleSearchRedirect = () => {
    if (query.trim()) {
      navigate(`/search/${query}`);
      setSearchResults([]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchRedirect();
    }
  };

  return (
    <div className="relative w-full md:max-w-lg px-4">
      <div
        className="relative flex items-center bg-white rounded-full shadow-lg"
        style={{ overflow: "hidden", height: "40px" }}
      >
        {/* Texto de Typed.js fuera del input */}
        {isTypedActive && (
          <div className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm pointer-events-none">
            <span ref={typedElement}></span>
          </div>
        )}

        {/* Input de búsqueda */}
        <input
          type="text"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
            handleSearch();
          }}
          onFocus={handleInputFocus}
          onKeyDown={handleKeyDown}
          placeholder="Buscar en Radeo!"
          className={`w-full px-4 py-1 text-sm rounded-full outline-none focus:ring-2 focus:ring-green-500 bg-white text-gray-700 placeholder-gray-400 transition-all md:text-base border-0`}
        />

        {/* Botón de búsqueda */}
        <button
          onClick={handleSearchRedirect}
          className="bg-yellow-400 h-full w-auto p-2 rounded-full flex items-center justify-center shadow-md"
          style={{ aspectRatio: "1" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="h-4 w-4 md:h-5 md:w-5 text-black"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-4.35-4.35M8.5 14.5a6 6 0 100-12 6 6 0 000 12z"
            />
          </svg>
        </button>

        {/* Botón de borrar */}
        {query && (
          <button
            onClick={handleClearSearch}
            className="absolute right-12 top-1/2 transform -translate-y-1/2 text-gray-600 transition"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="h-4 w-4 md:h-5 md:w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}
      </div>

      {/* Resultados */}
      {searchResults.length > 0 && (
        <ul className="absolute left-0 right-0 bg-white border border-gray-300 shadow-lg rounded-lg mt-2 max-h-80 overflow-y-auto z-50 text-sm md:text-base px-4">
          {searchResults.map((result) => (
            <li
              key={result.objectID}
              onClick={() => handleResultClick(result.objectID)}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer w-full"
            >
              <p className="font-semibold text-gray-800 truncate">
                {result.Título}
              </p>
              <p className="text-sm text-gray-500 truncate">
                {result.Marca} - {result.Modelo}
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AlgoliaSearch;
