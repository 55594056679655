import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "./context/userAuthContext";
import { CurrencyContext } from "./context/CurrencyContext";
import { db } from "./firebase";
import { doc, onSnapshot } from "firebase/firestore";
import AlgoliaSearch from "./AlgoliaSearch";
import {
  ShoppingCart,
  User,
  Tags,
  DollarSign,
  HardHatIcon,
  Menu,
  X,
} from "lucide-react";

const NavbarComponent = () => {
  const navigate = useNavigate();
  const { user, logOut } = useUserAuth();
  const { currency, toggleCurrency } = useContext(CurrencyContext);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [userType, setUserType] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const logoUrl =
    "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/radeo%2Fradeowhitelogo_797098c4-4b83-40c9-b29a-df23598ebe43%20(1).webp?alt=media&token=e5808804-10d5-4f15-a1c7-6f4c36c0d5b6";

  useEffect(() => {
    if (user?.uid) {
      const docRef = doc(db, "Users", user.uid);
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setCartItemCount(userData.cartItems?.length || 0);
          setUserType(userData.type || "");
        }
      });
      return () => unsubscribe();
    } else {
      setCartItemCount(0);
      setUserType("");
    }
  }, [user]);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/login");
      setIsMenuOpen(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <nav className="bg-[#0fb26d] shadow-lg fixed top-0 left-0 w-full z-50">
      <div className="container mx-auto px-4">
        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center justify-between h-16">
          <Link to="/" className="flex-shrink-0">
            <img
              src={logoUrl}
              alt="Logo"
              className="h-10 w-auto object-contain"
              loading="lazy"
            />
          </Link>

          <div className="flex-1 mx-8 max-w-2xl">
            <AlgoliaSearch className="!pb-0" />
          </div>

          <div className="flex items-center space-x-6">
            {userType === "admin" && (
              <Link
                to="/geekdashboard"
                className="bg-white text-[#0fb25d] px-3 py-1 rounded-full text-sm font-bold transition hover:bg-gray-200"
              >
                Admin
              </Link>
            )}
            <button
              onClick={() => navigate("/categorias")}
              className="text-white hover:text-gray-200 flex items-center space-x-1"
            >
              <Tags size={20} />
              <span>Categorías</span>
            </button>

            <button
              onClick={() => navigate("/instaladores")}
              className="bg-white text-[#0fb25d] px-4 py-2 rounded-lg font-bold flex items-center shadow-md hover:shadow-lg hover:bg-gray-200"
            >
              <HardHatIcon size={24} className="mr-2" />
              <span>Instaladores</span>
            </button>

            <button
              onClick={() => navigate(user ? "/profile" : "/login")}
              className="text-white hover:text-gray-200"
            >
              <User size={20} />
            </button>

            <button
              onClick={toggleCurrency}
              className="flex items-center space-x-2 bg-white text-[#0fb25d] px-3 py-1 rounded-full text-sm font-bold hover:bg-gray-200"
            >
              <DollarSign size={16} />
              <span>{currency}</span>
            </button>

            <Link
              to="/carrito"
              className="relative text-white hover:text-gray-200"
            >
              <div className="relative">
                <ShoppingCart size={24} />
                {cartItemCount > 0 && (
                  <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                    {cartItemCount}
                  </span>
                )}
              </div>
            </Link>
          </div>
        </div>

        {/* Mobile Navigation */}
        <div className="md:hidden flex items-center justify-between h-14">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-white p-1"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          <Link to="/" className="flex-shrink-0 ml-4">
            <img
              src={logoUrl}
              alt="Logo"
              className="h-8 w-auto object-contain"
              loading="lazy"
            />
          </Link>

          <div className="flex items-center space-x-4 w-full">
            <div className="flex-1">
              <AlgoliaSearch />
            </div>
            <Link to="/carrito" className="text-white relative">
              <div className="relative">
                <ShoppingCart size={24} />
                {cartItemCount > 0 && (
                  <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                    {cartItemCount}
                  </span>
                )}
              </div>
            </Link>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="absolute top-full left-0 right-0 bg-white shadow-lg">
            <div className="p-4 space-y-3">
              <button
                onClick={() => {
                  navigate("/categorias");
                  setIsMenuOpen(false);
                }}
                className="w-full flex items-center space-x-2 p-2 hover:bg-gray-100 rounded"
              >
                <Tags size={20} />
                <span>Categorías</span>
              </button>

              <button
                onClick={() => {
                  navigate("/instaladores");
                  setIsMenuOpen(false);
                }}
                className="w-full flex items-center space-x-2 p-2 hover:bg-gray-100 rounded"
              >
                <HardHatIcon size={20} />
                <span>Instaladores</span>
              </button>

              <button
                onClick={() => {
                  navigate(user ? "/profile" : "/login");
                  setIsMenuOpen(false);
                }}
                className="w-full flex items-center space-x-2 p-2 hover:bg-gray-100 rounded"
              >
                <User size={20} />
                <span>Perfil</span>
              </button>

              <button
                onClick={() => {
                  toggleCurrency();
                  setIsMenuOpen(false);
                }}
                className="w-full flex items-center space-x-2 p-2 hover:bg-gray-100 rounded"
              >
                <DollarSign size={20} />
                <span>{currency}</span>
              </button>

              {userType === "admin" && (
                <Link
                  to="/geekdashboard"
                  onClick={() => setIsMenuOpen(false)}
                  className="w-full flex items-center space-x-2 p-2 hover:bg-gray-100 rounded"
                >
                  Admin Dashboard
                </Link>
              )}
              <button
                onClick={handleLogout}
                className="w-full text-left flex items-center space-x-2 p-2 hover:bg-gray-100 rounded text-red-600 font-medium"
              >
                <X size={20} />
                <span>Cerrar sesión</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavbarComponent;
