import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay, Navigation } from "swiper/modules";

const products = [
  {
    id: 1,
    image:
      "https://ftp3.syscom.mx/usuarios/ftp/banners_index/syscom/hiksemi-promo-ago-sep-1080.jpg",
  },
  {
    id: 2,
    image:
      "https://ftp3.syscom.mx/usuarios/ftp/banners_index/syscom/3000x1080-switches.jpg",
  },
  {
    id: 3,
    image:
      "https://ftp3.syscom.mx/usuarios/ftp/banners_index/syscom/3000x1080-mejoresprecios.jpg",
  },
  {
    id: 4,
    image:
      "https://ftp3.syscom.mx/usuarios/ftp/banners_index/syscom/garage-madera-3000x1080.jpg",
  },
];

const Header = () => {
  return (
    <header className="w-full bg-gray-200 py-6 px-4">
      <Swiper
        modules={[Autoplay, Navigation]}
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
        navigation
        loop
        className="w-full"
      >
        {products.map((product) => (
          <SwiperSlide key={product.id}>
            <div className="w-full flex justify-center items-center">
              <img
                src={product.image}
                alt="Product Banner"
                className="w-full h-auto object-cover rounded-lg shadow-lg"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </header>
  );
};

export default Header;
