import React, { useEffect, useState } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Categorias = () => {
  const [categorias, setCategorias] = useState([]);
  const [subcategorias, setSubcategorias] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingSub, setLoadingSub] = useState({});
  const [expandedCategoria, setExpandedCategoria] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://radiosistemas-a46cd.uc.r.appspot.com/categorias"
        );
        setCategorias(response.data);
      } catch (err) {
        setError("Error al obtener las categorías.");
      } finally {
        setLoading(false);
      }
    };
    fetchCategorias();
  }, []);

  const fetchSubcategorias = async (categoriaId) => {
    if (subcategorias[categoriaId]) return;

    try {
      setLoadingSub((prev) => ({ ...prev, [categoriaId]: true }));
      const response = await axios.get(
        `https://radiosistemas-a46cd.uc.r.appspot.com/categorias/${categoriaId}`
      );
      setSubcategorias((prev) => ({
        ...prev,
        [categoriaId]: response.data.subcategorias || [],
      }));
    } catch (err) {
      console.error("Error obteniendo subcategorías:", err);
    } finally {
      setLoadingSub((prev) => ({ ...prev, [categoriaId]: false }));
    }
  };

  const handleToggle = (categoriaId) => {
    setExpandedCategoria((prev) => (prev === categoriaId ? null : categoriaId));
    fetchSubcategorias(categoriaId);
  };

  const handleSubcategoryClick = (subcatId) => {
    navigate(`/productos/categoria/${subcatId}`);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <motion.h1
          className="text-3xl md:text-5xl font-bold text-center mb-8 text-gray-800"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Explora Nuestras Categorías
        </motion.h1>

        {loading ? (
          <div className="flex justify-center items-center h-40">
            <div className="loader border-t-blue-600 animate-spin rounded-full w-12 h-12 border-4"></div>
          </div>
        ) : error ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center p-4 bg-red-100 rounded-lg text-red-700"
          >
            {error}
          </motion.div>
        ) : (
          <ul className="bg-white shadow-xl rounded-2xl overflow-hidden">
            <AnimatePresence>
              {categorias.map((categoria) => (
                <motion.li
                  key={categoria.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className="border-b last:border-none"
                >
                  <button
                    className="w-full px-6 py-4 bg-white hover:bg-gray-50 transition-all duration-300"
                    onClick={() => handleToggle(categoria.id)}
                  >
                    <div className="flex justify-between items-center">
                      <div className="text-center w-full">
                        <h3 className="text-xl font-semibold text-gray-800">
                          {categoria.nombre}
                        </h3>
                        <p className="text-sm text-gray-500 mt-1">
                          Nivel {categoria.nivel}
                        </p>
                      </div>
                      <motion.div
                        animate={{
                          rotate: expandedCategoria === categoria.id ? 180 : 0,
                        }}
                        transition={{ duration: 0.3 }}
                      >
                        <svg
                          className="w-6 h-6 text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </motion.div>
                    </div>
                  </button>

                  <motion.div
                    initial={{ height: 0 }}
                    animate={{
                      height: expandedCategoria === categoria.id ? "auto" : 0,
                    }}
                    transition={{ duration: 0.3 }}
                    className="overflow-hidden bg-gray-50"
                  >
                    {loadingSub[categoria.id] ? (
                      <div className="flex justify-center items-center py-8">
                        <div className="loader border-t-blue-600 animate-spin rounded-full w-8 h-8 border-3"></div>
                      </div>
                    ) : (
                      <div className="p-6 flex flex-wrap justify-center gap-3">
                        {subcategorias[categoria.id]?.length > 0 ? (
                          subcategorias[categoria.id].map((subcat) => (
                            <motion.button
                              key={subcat.id}
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              className="px-6 py-2 bg-blue-600 text-white rounded-full text-sm font-medium hover:bg-blue-700 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                              onClick={() => handleSubcategoryClick(subcat.id)}
                            >
                              {subcat.nombre}
                            </motion.button>
                          ))
                        ) : (
                          <p className="text-center text-gray-500 py-4">
                            No hay subcategorías disponibles.
                          </p>
                        )}
                      </div>
                    )}
                  </motion.div>
                </motion.li>
              ))}
            </AnimatePresence>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Categorias;
