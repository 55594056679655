import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Home, ShoppingCart, Search, User } from "lucide-react";

export default function MobileNavbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const routes = [
    { path: "/", label: "Home", icon: Home },
    { path: "/carrito", label: "Carrito", icon: ShoppingCart },
    { path: "/buscar", label: "Buscar", icon: Search },
    { path: "/profile", label: "Perfil", icon: User },
  ];

  const getCurrentValue = () => {
    return routes.findIndex((route) => route.path === location.pathname);
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 bg-white shadow-md rounded-t-2xl">
      <div className="flex justify-around items-center h-16">
        {routes.map((route, index) => {
          const isActive = getCurrentValue() === index;
          return (
            <button
              key={index}
              onClick={() => navigate(route.path)}
              className={`flex flex-col items-center text-sm font-medium ${
                isActive ? "text-[#232f3e]" : "text-gray-500"
              } hover:text-[#232f3e] transition`}
            >
              <route.icon
                size={24}
                className={`${
                  isActive ? "translate-y-[-2px]" : ""
                } transition-transform`}
              />
              <span
                className={`${
                  isActive ? "text-xs opacity-100" : "text-xs opacity-70"
                } transition-opacity`}
              >
                {route.label}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
}
