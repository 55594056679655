import React from "react";

const ServiciosHeader = () => {
  return (
    <header className="bg-gradient-to-r from-green-500 to-green-600 text-white py-16 px-6">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        {/* Texto informativo */}
        <div className="flex flex-col items-center md:items-start">
          <h1 className="text-4xl md:text-5xl font-bold mb-6 text-center md:text-left">
            Trabajo de calidad y confianza
          </h1>
          <p className="text-lg md:text-xl mb-4 text-center md:text-left">
            <strong>Mano de obra calificada:</strong> experta y especializada.
          </p>
          <p className="text-lg md:text-xl mb-4 text-center md:text-left">
            <strong>Confiable:</strong> Instaladores asegurados, autorizados y
            avalados por <span className="font-bold">Radeo</span>.
          </p>
          <p className="text-sm italic bg-white text-green-600 px-4 py-2 rounded-lg shadow-md inline-block mb-6">
            *La visita tiene un costo, mismo que es reembolsable cuando se
            contrata el servicio.
          </p>
        </div>

        {/* Imagen */}
        <div className="flex justify-center">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/radeomexico.firebasestorage.app/o/Servicios%2FDALL%C2%B7E%202024-11-30%2012.30.12%20-%20A%20friendly%20and%20approachable%20cartoon-style%20professional%20installer%20depicted%20in%20a%20more%20human%20and%20realistic%20way.%20The%20character%20is%20standing%20on%20a%20ladder%2C%20us.webp?alt=media&token=a02a08f4-53ad-4470-864c-e074ae83ec17"
            alt="Servicios Técnicos"
            className="rounded-xl shadow-2xl w-full max-w-sm md:max-w-full object-cover"
          />
        </div>
      </div>

      {/* Información adicional */}
      <div className="mt-12 bg-white text-green-600 px-8 py-6 rounded-xl shadow-lg">
        <p className="text-lg font-semibold text-center">
          <strong>
            Para servicios especializados que requieren visita técnica:
          </strong>
        </p>
        <ul className="mt-6 space-y-4 text-gray-800 text-base">
          <li className="flex items-start space-x-3">
            <span className="h-4 w-4 bg-green-600 rounded-full flex-shrink-0 mt-1"></span>
            <p>
              Nuestro técnico tomará medidas y calculará los materiales
              necesarios.
            </p>
          </li>
          <li className="flex items-start space-x-3">
            <span className="h-4 w-4 bg-green-600 rounded-full flex-shrink-0 mt-1"></span>
            <p>
              Recibe por parte de la tienda que estará dando el seguimiento, la
              cotización de tu proyecto.
            </p>
          </li>
          <li className="flex items-start space-x-3">
            <span className="h-4 w-4 bg-green-600 rounded-full flex-shrink-0 mt-1"></span>
            <p>
              Tras la contratación y pago correspondiente de la cotización,
              programaremos contigo la fecha del servicio de instalación.
            </p>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default ServiciosHeader;
