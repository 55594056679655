import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { CheckCircle, XCircle, Package } from "lucide-react";

const Search = () => {
  const { input } = useParams();
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://radiosistemas-a46cd.uc.r.appspot.com/productos/search?query=${input}&pagina=${pagina}&cantidad=20`
        );
        if (response.data.productos) {
          setProductos(response.data.productos);
          setTotalPaginas(response.data.paginas);
        } else {
          setError("No se encontraron productos.");
        }
      } catch (err) {
        setError("Error al obtener productos.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProductos();
  }, [input, pagina]);

  // Generar reseñas y calificaciones aleatorias
  const generateRandomReviews = () => {
    const rating = (Math.random() * 2 + 3).toFixed(1); // Entre 3.0 y 5.0
    const reviews = Math.floor(Math.random() * 1000) + 1; // Entre 1 y 1000
    return { rating, reviews };
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(price);
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <h1 className="text-2xl font-semibold text-center mb-6">
        Resultados de la búsqueda: {input}
      </h1>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-10 w-10 border-4 border-blue-500 border-t-transparent rounded-full"></div>
        </div>
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : (
        <div className="space-y-4">
          {productos.length > 0 ? (
            productos.map((producto) => {
              const { rating, reviews } = generateRandomReviews();
              return (
                <div
                  key={producto.producto_id}
                  className="flex flex-col sm:flex-row items-start border border-gray-200 rounded-lg shadow-sm p-4 hover:shadow-lg transition-shadow duration-300"
                >
                  {/* Imagen del producto */}
                  <Link
                    to={`/producto/${producto.modelo.replace(/\//g, "-")}`}
                    className="block flex-shrink-0 w-full sm:w-1/4"
                  >
                    <img
                      src={
                        producto.img_portada ||
                        "https://via.placeholder.com/300"
                      }
                      alt={producto.titulo}
                      className="w-full h-48 object-contain"
                    />
                  </Link>

                  {/* Detalles del producto */}
                  <div className="flex-grow sm:ml-4 mt-4 sm:mt-0">
                    <h2 className="text-lg font-semibold text-gray-800 mb-2">
                      {producto.titulo}
                    </h2>
                    <div className="flex items-center mb-2">
                      <div className="flex items-center space-x-1">
                        {Array.from({ length: 5 }).map((_, i) => (
                          <span
                            key={i}
                            className={`h-4 w-4 ${
                              i < Math.floor(rating)
                                ? "text-yellow-500"
                                : "text-gray-300"
                            }`}
                          >
                            ★
                          </span>
                        ))}
                      </div>
                      <p className="text-sm text-gray-600 ml-2">({reviews})</p>
                    </div>
                    <p className="text-xl font-bold text-blue-600 mb-1">
                      {formatPrice(producto.precios?.precio_lista || 0)}
                    </p>
                    <p className="text-sm text-gray-500 line-through">
                      {formatPrice((producto.precios?.precio_lista || 0) * 1.2)}
                    </p>
                    <p className="text-sm text-gray-600">
                      Hasta 12 meses de{" "}
                      {formatPrice((producto.precios?.precio_lista || 0) / 12)}
                    </p>
                    {producto.peso < 30 && (
                      <p className="text-sm text-green-600 flex items-center mt-2">
                        🚚 Envío Gratis
                      </p>
                    )}
                    <p className="text-sm text-gray-600 flex items-center mt-2">
                      <Package className="w-5 h-5 text-gray-500 mr-1" />
                      Existencias:{" "}
                      {producto.total_existencia > 0 ? (
                        <span className="text-green-600 flex items-center">
                          <CheckCircle className="w-4 h-4 mr-1" />
                          {producto.total_existencia}
                        </span>
                      ) : (
                        <span className="text-red-600 flex items-center">
                          <XCircle className="w-4 h-4 mr-1" />
                          Sin existencias
                        </span>
                      )}
                    </p>
                  </div>

                  {/* Acciones */}
                  <div className="mt-4 sm:mt-0 sm:ml-auto flex flex-col items-start sm:items-end">
                    <button className="flex items-center px-4 py-2 bg-yellow-400 text-white rounded-md hover:bg-yellow-500 w-full sm:w-auto">
                      🛒 Agregar al carrito
                    </button>
                    <button className="text-red-500 hover:text-red-600 mt-2">
                      ♥ Agregar a favoritos
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-center text-gray-600">
              No se encontraron productos.
            </p>
          )}
        </div>
      )}
      {/* Paginación */}
      <div className="flex justify-center mt-8 space-x-2">
        <button
          onClick={() => setPagina((prev) => Math.max(prev - 1, 1))}
          className="px-4 py-2 bg-gray-200 rounded-l-md hover:bg-gray-300"
        >
          Anterior
        </button>
        <div className="px-4 py-2 bg-gray-100">{pagina}</div>
        <button
          onClick={() => setPagina((prev) => prev + 1)}
          className="px-4 py-2 bg-gray-200 rounded-r-md hover:bg-gray-300"
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default Search;
