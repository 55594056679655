import React from "react";
import { useCart } from "../../../context/CartContext";
import { Minus, Plus, Trash } from "lucide-react";

const Cart = () => {
  const {
    cartItems,
    removeFromCart,
    clearCart,
    calculateTotal,
    updateCartItemQuantity,
  } = useCart();

  // Log para verificar los datos almacenados en el carrito
  console.log("Datos del carrito:", cartItems);

  return (
    <div className="p-6 max-w-4xl mx-auto bg-white shadow-lg rounded-lg">
      {/* Encabezado */}
      <h1 className="text-3xl font-semibold text-gray-800 mb-8 text-center">
        🛒 Mi Carrito de Compras
      </h1>

      {/* Si el carrito está vacío */}
      {cartItems.length === 0 ? (
        <div className="text-center text-gray-600">
          <p className="text-lg">Tu carrito está vacío.</p>
          <a
            href="/"
            className="mt-4 inline-block text-green-500 hover:underline"
          >
            Ir a comprar
          </a>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Lista de productos */}
          <div className="col-span-2 space-y-6">
            {cartItems.map((item) => (
              <div
                key={item.id}
                className="flex items-center justify-between bg-gray-50 rounded-lg p-4 shadow-sm"
              >
                {/* Imagen y detalles */}
                <div className="flex items-center gap-4">
                  <img
                    src={item.image || "https://via.placeholder.com/150"}
                    alt={item.name || "Producto"}
                    className="w-24 h-24 object-cover rounded-md"
                  />
                  <div>
                    <p className="text-lg font-medium text-gray-800">
                      {item.name || "Producto"}
                    </p>
                    <p className="text-sm text-gray-500">
                      Precio unitario: ${item.price?.toFixed(2) || "N/A"}
                    </p>
                    <div className="flex items-center gap-2 mt-2">
                      {/* Controles de cantidad */}
                      <button
                        onClick={() =>
                          updateCartItemQuantity(item.id, item.quantity - 1)
                        }
                        className="p-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-full transition"
                        disabled={item.quantity <= 1}
                      >
                        <Minus size={16} />
                      </button>
                      <span className="text-gray-800 font-medium">
                        {item.quantity}
                      </span>
                      <button
                        onClick={() =>
                          updateCartItemQuantity(item.id, item.quantity + 1)
                        }
                        className="p-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-full transition"
                      >
                        <Plus size={16} />
                      </button>
                    </div>
                  </div>
                </div>

                {/* Botón de eliminar */}
                <button
                  onClick={() => removeFromCart(item.id)}
                  className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition"
                >
                  <Trash size={20} />
                </button>
              </div>
            ))}
          </div>

          {/* Resumen del carrito */}
          <div className="bg-gray-50 rounded-lg shadow-md p-6 sticky top-4">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Resumen del Pedido
            </h2>
            <div className="flex justify-between text-lg mb-2">
              <span className="text-gray-700">Subtotal</span>
              <span className="text-gray-800 font-medium">
                ${calculateTotal().toFixed(2)}
              </span>
            </div>
            <div className="flex justify-between text-sm text-gray-500 mb-4">
              <span>Envío</span>
              <span>Gratis</span>
            </div>
            <p className="text-sm text-gray-500 mb-6">
              * IVA incluido donde aplique
            </p>
            <button
              onClick={clearCart}
              className="w-full py-3 bg-red-500 text-white font-medium rounded-lg shadow-lg hover:bg-red-600 transition mb-3"
            >
              Vaciar Carrito
            </button>
            <a
              href="/checkout"
              className="w-full py-3 bg-green-500 text-white text-center font-medium rounded-lg shadow-lg hover:bg-green-600 transition block"
            >
              Proceder al Pago
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
