import React from "react";
import ServiciosHeader from "./ServiciosHeader";
import Instaladores from "./Instaladores";

const Servicios = () => {
  return (
    <div>
      <ServiciosHeader />
      <Instaladores />
    </div>
  );
};

export default Servicios;
