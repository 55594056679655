import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TextField, Button, Alert, Grid, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import GoogleIcon from "../assets/google-icon.png";
import { useUserAuth } from "../context/userAuthContext";

const GoogleButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#0fb26d",
  borderColor: "#0fb26d",
  textTransform: "none",
  fontSize: "16px",
  padding: "10px 20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "#f1f1f1",
    borderColor: "#0fb26d",
  },
});

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showLoginForm, setShowLoginForm] = useState(false);
  const { logIn, googleSignIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      navigate("/home");
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/home");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh",
        backgroundColor: "#0fb26d",
        padding: "0 20px",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/radeo%2Fradeowhitelogo_797098c4-4b83-40c9-b29a-df23598ebe43%20(1).webp?alt=media&token=e5808804-10d5-4f15-a1c7-6f4c36c0d5b6"
            alt="Livespeed Logo"
            style={{ width: "150px", marginBottom: "20px" }}
          />
        </Box>
        <Box
          sx={{
            p: 4,
            backgroundColor: "#f9f9f9",
            borderRadius: "10px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            border: "1px solid #ddd",
          }}
        >
          {error && (
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          )}
          {!showLoginForm && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setShowLoginForm(true)}
              sx={{
                py: 1.5,
                backgroundColor: "#0fb26d",
                color: "#fff",
                fontSize: "16px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#09a45d",
                },
              }}
            >
              Iniciar Sesión
            </Button>
          )}
          {showLoginForm && (
            <form onSubmit={handleSubmit}>
              <TextField
                label="Correo Electrónico"
                variant="outlined"
                fullWidth
                margin="normal"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />

              <TextField
                label="Contraseña"
                variant="outlined"
                fullWidth
                margin="normal"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                sx={{
                  mt: 3,
                  py: 1.5,
                  backgroundColor: "#0fb26d",
                  color: "#fff",
                  fontSize: "16px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#09a45d",
                  },
                }}
              >
                Iniciar Sesión
              </Button>
            </form>
          )}

          <Box sx={{ mt: 3 }}>
            <GoogleButton
              variant="outlined"
              onClick={handleGoogleSignIn}
              fullWidth
              startIcon={<img src={GoogleIcon} alt="Google" width="20" />}
              sx={{
                py: 1.5,
                fontSize: "16px",
                borderRadius: "8px",
              }}
            >
              Iniciar Sesión con Google
            </GoogleButton>
          </Box>

          <Typography
            variant="body2"
            align="center"
            sx={{ mt: 3, color: "#000" }}
          >
            ¿No tienes una cuenta?{" "}
            <Link
              to="/signup"
              style={{ color: "#0fb26d", textDecoration: "underline" }}
            >
              ¡Crea una!
            </Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
