import React, { useState } from "react";
import { ChevronLeft, ChevronRight, ZoomIn, X } from "lucide-react";

const ImageSlider = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [zoomOpen, setZoomOpen] = useState(false);
  const [zoomImage, setZoomImage] = useState("");

  // Navigation handlers
  const goToPrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const handleThumbnailClick = (index) => {
    setCurrentSlide(index);
  };

  const handleZoomClick = (img) => {
    setZoomImage(img);
    setZoomOpen(true);
  };

  const handleCloseZoom = () => {
    setZoomOpen(false);
  };

  return (
    <div className="container mx-auto px-4 py-6 max-w-6xl">
      {/* Main Slider Container */}
      <div className="relative w-full group">
        {/* Navigation Arrows */}
        <button
          onClick={goToPrevSlide}
          className="absolute left-2 top-1/2 -translate-y-1/2 z-10 
            bg-white/50 hover:bg-white/70 rounded-full p-2 
            transition-all duration-300 ease-in-out 
            opacity-0 group-hover:opacity-100"
        >
          <ChevronLeft className="w-6 h-6 text-gray-800" />
        </button>
        <button
          onClick={goToNextSlide}
          className="absolute right-2 top-1/2 -translate-y-1/2 z-10 
            bg-white/50 hover:bg-white/70 rounded-full p-2 
            transition-all duration-300 ease-in-out 
            opacity-0 group-hover:opacity-100"
        >
          <ChevronRight className="w-6 h-6 text-gray-800" />
        </button>

        {/* Main Image */}
        <div className="w-full aspect-square md:aspect-video overflow-hidden rounded-lg shadow-lg">
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
          >
            {images.map((img, index) => (
              <div
                key={index}
                className="w-full flex-shrink-0 flex justify-center items-center relative"
              >
                <img
                  src={img.imagen}
                  alt={`Slide ${index + 1}`}
                  className="max-w-full max-h-full object-contain"
                  onClick={() => handleZoomClick(img.imagen)}
                />
                <button
                  onClick={() => handleZoomClick(img.imagen)}
                  className="absolute top-4 right-4 bg-black/40 hover:bg-black/60 
                    rounded-full p-2 transition-colors duration-300"
                >
                  <ZoomIn className="w-6 h-6 text-white" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Thumbnails */}
      <div className="flex justify-center mt-4 space-x-2 overflow-x-auto scrollbar-hide">
        {images.map((img, index) => (
          <button
            key={index}
            onClick={() => handleThumbnailClick(index)}
            className={`
              w-16 h-16 rounded-md overflow-hidden transition-all duration-300
              ${
                currentSlide === index
                  ? "ring-2 ring-blue-500 scale-105"
                  : "ring-1 ring-transparent hover:ring-gray-300"
              }
            `}
          >
            <img
              src={img.imagen}
              alt={`Thumbnail ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </button>
        ))}
      </div>

      {/* Zoom Modal */}
      {zoomOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center 
          bg-black bg-opacity-90 p-4 animate-fade-in"
          onClick={handleCloseZoom}
        >
          <div
            className="relative max-w-[90%] max-h-[90%]"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={handleCloseZoom}
              className="absolute -top-10 right-0 text-white 
              hover:bg-white/20 rounded-full p-2 transition-colors"
            >
              <X className="w-8 h-8" />
            </button>
            <img
              src={zoomImage}
              alt="Zoomed"
              className="max-w-full max-h-full object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
