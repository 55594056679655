import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import {
  Tv,
  Tag,
  ShoppingCart,
  Smartphone,
  Home,
  Shirt,
  Dog,
} from "lucide-react";

const categories = [
  {
    id: 1,
    icon: <Tv size={32} className="text-blue-600" />,
    title: "Electrónicos",
    color: "hover:bg-blue-50",
  },
  {
    id: 2,
    icon: <Tag size={32} className="text-yellow-600" />,
    title: "Ofertas",
    color: "hover:bg-yellow-50",
  },
  {
    id: 3,
    icon: <ShoppingCart size={32} className="text-green-600" />,
    title: "Super",
    color: "hover:bg-green-50",
  },
  {
    id: 4,
    icon: <Smartphone size={32} className="text-red-600" />,
    title: "Celulares",
    color: "hover:bg-red-50",
  },
  {
    id: 5,
    icon: <Home size={32} className="text-purple-600" />,
    title: "Hogar",
    color: "hover:bg-purple-50",
  },
  {
    id: 6,
    icon: <Shirt size={32} className="text-pink-600" />,
    title: "Ropa",
    color: "hover:bg-pink-50",
  },
  {
    id: 7,
    icon: <Dog size={32} className="text-orange-600" />,
    title: "Mascotas",
    color: "hover:bg-orange-50",
  },
];

const CategorySlider = () => {
  return (
    <div className="w-full bg-white py-8 px-4">
      <Swiper
        modules={[Autoplay]}
        spaceBetween={20}
        slidesPerView={2.5}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          480: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 5,
          },
          1024: {
            slidesPerView: 7,
          },
        }}
        className="px-4"
      >
        {categories.map((category) => (
          <SwiperSlide key={category.id}>
            <button className="w-full group">
              <div className="flex flex-col items-center space-y-3">
                {/* Contenedor del ícono con animación y hover */}
                <div
                  className={`
                  w-20 h-20 
                  flex justify-center items-center 
                  bg-white rounded-full
                  shadow-lg
                  transition-all duration-300 
                  transform group-hover:scale-110
                  ${category.color}
                  border-2 border-gray-100
                `}
                >
                  {category.icon}
                </div>

                {/* Texto con mejor tipografía y animación */}
                <span
                  className="
                  text-sm font-medium
                  text-gray-700
                  group-hover:text-gray-900
                  transition-colors duration-300
                  text-center
                  whitespace-nowrap
                  px-1
                "
                >
                  {category.title}
                </span>
              </div>
            </button>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CategorySlider;
