import React, { useState } from "react";
import { ArrowRight, Phone, MapPin } from "lucide-react";

// Categorías de instaladores
const categories = [
  { id: "solar", name: "Paneles Solares" },
  { id: "smart_home", name: "Casa Inteligente" },
  { id: "security", name: "Cámaras de Seguridad" },
  { id: "mini_split", name: "Mini Split" },
  { id: "electricity", name: "Electricidad" },
  { id: "telecommunications", name: "Telecomunicaciones" },
  { id: "other", name: "Otros" },
];

// Lista de instaladores
const installers = [
  {
    id: 3,
    name: "ARSY",
    category: "security",
    location: "Hermosillo",
    rating: 4.9,
    phone: "818-456-7890",
    image:
      "https://mir-s3-cdn-cf.behance.net/projects/404/6c13d182932783.Y3JvcCwzNDU5LDI3MDYsMTExMywyNDg.png",
  },
  {
    id: 4,
    name: "RESCOM",
    category: "security",
    location: "Hermosillo",
    rating: 4.8,
    phone: "662-210-5561",
    image: "https://rescom.mx/wp-content/uploads/2015/06/Logo-Rescom.png",
    services: [
      "Cámaras de Seguridad (CCTV)",
      "Enlaces Inalámbricos de Datos",
      "Planeación e Implementación de los Enlaces",
      "Sistemas de Detección de Incendio",
      "Pólizas de Mantenimiento Correctivo y Preventivo",
      "Radios de Comunicación",
    ],
  },
  {
    id: 5,
    name: "Teli",
    category: "telecommunications",
    location: "Hermosillo",
    rating: 4.7,
    phone: "(662) 211 9613",
    image: "https://teli.com.mx/imagen/logo.png",
    services: [
      "Internet Comercial",
      "Internet Residencial",
      "Internet Empresarial",
      "Internet Rural",
      "Internet para Eventos",
      "Internet Dedicado",
      "Líneas Telefónicas",
      "Telefonía IP",
      "Redes y Telecomunicaciones",
    ],
  },
  {
    id: 6,
    name: "Termocontrol",
    category: "mini_split",
    location: "Hermosillo",
    rating: 4.9,
    phone: "01 662-210-4213",
    image: "http://termocontrol.com.mx/images/logotermocontrol.png",
    services: [
      "Sistemas de refrigeración",
      "Ventilación, aire acondicionado y calefacción",
      "Soporte técnico para constructores",
      "Control ambiental",
    ],
    description:
      "Especialistas en control ambiental del espacio laboral o social desde 1990.",
  },
];

const Instaladores = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // Filtrar instaladores según categoría y término de búsqueda
  const filteredInstallers = installers.filter((installer) => {
    const matchesCategory =
      selectedCategory === "" || installer.category === selectedCategory;
    const matchesSearch = installer.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col items-center py-8 px-4">
      <h1 className="text-3xl md:text-4xl font-extrabold text-gray-800 mb-8 text-center">
        Encuentra Instaladores Profesionales
      </h1>

      {/* Categorías */}
      <div className="flex flex-wrap justify-center gap-3 mb-6">
        <button
          key="all"
          onClick={() => setSelectedCategory("")}
          className={`px-4 md:px-6 py-2 rounded-full font-medium text-sm shadow-md ${
            selectedCategory === ""
              ? "bg-green-600 text-white"
              : "bg-white text-gray-800 border border-gray-300"
          } hover:bg-green-700 hover:text-white transition`}
        >
          Todas
        </button>
        {categories.map((category) => (
          <button
            key={category.id}
            onClick={() =>
              setSelectedCategory(category.id === selectedCategory ? "" : category.id)
            }
            className={`px-4 md:px-6 py-2 rounded-full font-medium text-sm shadow-md ${
              selectedCategory === category.id
                ? "bg-green-600 text-white"
                : "bg-white text-gray-800 border border-gray-300"
            } hover:bg-green-700 hover:text-white transition`}
          >
            {category.name}
          </button>
        ))}
      </div>

      {/* Barra de búsqueda */}
      <div className="w-full max-w-md mb-6">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Busca un instalador por nombre..."
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-600 focus:outline-none"
        />
      </div>

      {/* Resultados */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl">
        {filteredInstallers.length > 0 ? (
          filteredInstallers.map((installer) => (
            <div
              key={installer.id}
              className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition"
            >
              <div className="relative w-full h-40 md:h-48">
                <img
                  src={installer.image}
                  alt={installer.name}
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
              <div className="p-4">
                <h2 className="text-lg font-bold text-gray-800 mb-1">
                  {installer.name}
                </h2>
                <p className="flex items-center text-gray-600 mb-1">
                  <MapPin className="mr-2" size={18} />
                  {installer.location}
                </p>
                <p className="flex items-center text-gray-600 mb-1">
                  <Phone className="mr-2" size={18} />
                  {installer.phone}
                </p>
                <p className="text-gray-600 mb-2">
                  <strong>Calificación:</strong> ⭐ {installer.rating.toFixed(1)}
                </p>
                {installer.services && (
                  <ul className="text-sm text-gray-600 mb-4">
                    {installer.services.slice(0, 3).map((service, index) => (
                      <li key={index}>• {service}</li>
                    ))}
                    {installer.services.length > 3 && (
                      <li className="text-green-600 font-semibold">
                        + Más servicios...
                      </li>
                    )}
                  </ul>
                )}
                <button
                  onClick={() =>
                    alert(`Contactando a ${installer.name} al ${installer.phone}...`)
                  }
                  className="w-full flex items-center justify-center bg-green-600 text-white font-bold py-2 rounded-lg shadow-md hover:bg-green-700 transition"
                >
                  Contactar <ArrowRight className="ml-2" size={20} />
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-600 col-span-full">
            No se encontraron instaladores para esta búsqueda.
          </p>
        )}
      </div>
    </div>
  );
};

export default Instaladores;
